/**
 * Used as an alternative to v-html in order
 * to process html entities.
 * Optionally strip tags.
 * @param {string} html
 * @param {boolean} stripTags
 * @return {string}
 */
export const decodeHTML = (html, stripTags = false) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    if (stripTags)
        return txt.textContent;
    return txt.value;
};
/**
 * Convert to Title Case
 * @link https://gist.github.com/kieranbarker/293b74f1b3b46272315d2e1719786b03
 * @param {string} str
 * @returns {string}
 */
export const convertToTitleCase = (str) => {
    return str
        .toLowerCase()
        .split(' ')
        .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
    })
        .join(' ');
};
/**
 * Convert to Sentence Case
 * @param {string} str
 * @returns {string}
 */
export const convertToSentenceCase = (str) => {
    const firstChar = str.charAt(0).toUpperCase();
    const restStr = str.slice(1).toLowerCase();
    return `${firstChar}${restStr}`;
};
/**
 * Convert camelCase to Title Case
 * @param {string} str
 * @returns {string}
 */
export const camelCaseToTitleCase = (str) => {
    const result = str.replace(/([A-Z])/g, ' $1');
    return convertToTitleCase(result);
};
/**
 * Hyphenate
 * Hyphenates a string and converts to lowercase.
 * @param {string} str
 * @return {string}
 */
export const hyphenate = (str = '') => {
    const text = str.toLowerCase().replace(/\W+/g, '-');
    const startsWithHyphen = text.startsWith('-');
    return startsWithHyphen ? text.slice(1) : text;
};
/**
 * Capitaises the first letter of a string.
 * @param {string} text
 * @returns {string}
 */
export const capitaliseFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};
/**
 * Convert to search term. Converts to lower case and replaces spaces with pluses.
 * @param {string} str
 * @returns {string}
 */
export const convertToSearchTerm = (str) => {
    return str.toLowerCase().replace(/\s+/g, '+');
};
/**
 * Generate a unique id.
 * @returns {string}
 */
export const generateUniqueId = () => {
    return Math.random().toString(36).substring(7);
};
